import Base from "./base";

export default class Spotlight extends Base {
  async getSpotlights () {
    return await this.makeGetRequest("/spotlights");
  }

  async getMovieSpotlights () {
    return await this.makeGetRequest("/spotlights/movies");
  }

  async getTVSpotlights () {
    return await this.makeGetRequest("/spotlights/tvs");
  }

  async getGameSpotlights () {
    return await this.makeGetRequest("/spotlights/games");
  }

  async getBookSpotlights () {
    return await this.makeGetRequest("/spotlights/books");
  }
}
