import Base from "./base";

export default class TV extends Base {
  async getDetailInfo (slug) {
    return await this.makeGetRequest(`/tvs/${slug}`);
  }

  async getSeasonInfo (slug, seasonNumber) {
    return await this.makeGetRequest(`/tvs/${slug}/seasons/${seasonNumber}`);
  }

  async getEpisodeInfo (slug, seasonNumber, episodeNumber) {
    return await this.makeGetRequest(`/tvs/${slug}/seasons/${seasonNumber}/episodes/${episodeNumber}`);
  }

  async getTopRated (offset, limit, params) {
    return await this.makeGetRequest("/tvs/top-rated", { offset, limit, ...params });
  }

  async getOverYears (offset, limit, params) {
    return await this.makeGetRequest("/tvs/over-years", { offset, limit, ...params });
  }

  async getPopular (offset, limit, params) {
    return await this.makeGetRequest("/tvs/popular", { offset, limit, ...params });
  }

  async getRelease (offset, limit, params) {
    return await this.makeGetRequest("/tvs/release-calendar", { offset, limit, ...params });
  }

  async getTopKeywords (offset, limit, params) {
    return await this.makeGetRequest("/tvs/top-keywords", { offset, limit, ...params });
  }

  async getCast (slug) {
    return await this.makeGetRequest(`/tvs/${slug}/cast`);
  }

  async getCrew (slug) {
    return await this.makeGetRequest(`/tvs/${slug}/crew`);
  }

  async getCastInSeason (slug, seasonNumber, offset, limit) {
    return await this.makeGetRequest(`/tvs/${slug}/seasons/${seasonNumber}/cast`, { offset, limit });
  }

  async getCrewInSeason (slug, seasonNumber, offset, limit) {
    return await this.makeGetRequest(`/tvs/${slug}/seasons/${seasonNumber}/crew`, { offset, limit });
  }

  async getCastInEpisode (slug, seasonNumber, episodeNumber, offset, limit) {
    return await this.makeGetRequest(`/tvs/${slug}/seasons/${seasonNumber}/episodes/${episodeNumber}/cast`, { offset, limit });
  }

  async getCrewInEpisode (slug, seasonNumber, episodeNumber, offset, limit) {
    return await this.makeGetRequest(`/tvs/${slug}/seasons/${seasonNumber}/episodes/${episodeNumber}/crew`, { offset, limit });
  }
}
