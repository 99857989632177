<template>
  <v-container>
    <BackgroundColor />
    <v-row v-if="$store.state.user === null">
      <v-col>
        <v-container>
          <v-row class="pt-16">
            <v-col style="z-index:100">
              <h1 class="mb-2 text-h4 font-weight-bold text-center">
                Your Ultimate Media Hub: Track, Discover, and Share
              </h1>
              <h3 class="text-h6 font-weight-bold text-center mb-16">
                Track your favorite Movies, TV series, Books, and Games in one place.
              </h3>
              <p class="text-center">
                <v-btn
                  outlined
                  rounded
                  @click="$root.$emit('eventFromLogin')"
                >
                  welcome to the club!
                </v-btn>
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row justify="center" class="mb-16">
            <v-col cols="12" sm="10">
              <v-container>
                <v-row>
                  <v-col cols="6" md="3">
                    <v-list class="rounded-xl">
                      <v-card-text class="text-center text-subtitle-2">
                        Create thematic lists and share them with your friends.
                      </v-card-text>
                    </v-list>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-list class="rounded-xl">
                      <v-card-text class="text-center text-subtitle-2">
                        Track your favorite Movies, TV series, Books, and Games in one place.
                      </v-card-text>
                    </v-list>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-list class="rounded-xl">
                      <v-card-text class="text-center text-subtitle-2">
                        Follow your friends and see what they are watching, reading, and playing.
                      </v-card-text>
                    </v-list>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-list class="rounded-xl">
                      <v-card-text class="text-center text-subtitle-2">
                        Explore your stats and see how your taste compares to others.
                      </v-card-text>
                    </v-list>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-list class="rounded-xl">
                      <v-card-text class="text-center text-subtitle-2">
                        Write reviews and engage in discussions with other members.
                      </v-card-text>
                    </v-list>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-list class="rounded-xl">
                      <v-card-text class="text-center text-subtitle-2">
                        Mark entertainment and get notified when your favorite media is out.
                      </v-card-text>
                    </v-list>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-list class="rounded-xl">
                      <v-card-text class="text-center text-subtitle-2">
                        Get personalized recommendations based on your taste.
                      </v-card-text>
                    </v-list>
                  </v-col>
                  <v-col cols="6" md="3">
                    <v-list class="rounded-xl">
                      <v-card-text class="text-center text-subtitle-2">
                        Join our community and stay connected to the latest releases.
                      </v-card-text>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-lazy
      v-model="isMovieListActive"
      min-height="400px"
    >
      <SimpleCarousel
        title="Trending Movies"
        subtitle="Must-watch movies to discover"
        :items="hotFilms"
        :component="'MovieCard'"
        link="/movie/most-popular"
        class="mb-8"
      />
    </v-lazy>
    <v-lazy
      v-model="isMovieComingListActive"
      min-height="400px"
    >
      <SimpleCarousel
        title="Coming Soon"
        subtitle="Upcoming movies to watch out for"
        :items="comingFilms"
        :component="'MovieCard'"
        link="/movie/release-calendar"
        class="mb-8"
      />
    </v-lazy>
    <v-lazy
      v-model="isMovieSpotlightListActive"
      min-height="400px"
    >
      <SimpleCarousel
        title="Spotlights"
        subtitle="It's time for"
        :items="movieSpotlights"
        :component="'MovieCard'"
        link="/spotlight"
        class="mb-8"
      />
    </v-lazy>
    <v-lazy
      v-model="isMovieKeywordActive"
    >
      <KeywordCarousel
        title="Top Movie Keywords"
        subtitle="Popular Movie keywords to explore"
        :items="movieKeywords"
        base-link="/discover/movies/keywords"
        class="mb-16"
      />
    </v-lazy>
    <v-lazy
      v-model="isTVListActive"
      min-height="400px"
    >
      <SimpleCarousel
        title="Trending TV Shows"
        subtitle="Popular series to binge-watch"
        :items="hotShows"
        :component="'TVCard'"
        link="/tv/most-popular"
        class="mb-8"
      />
    </v-lazy>
    <v-lazy
      v-model="isTVComingListActive"
      min-height="400px"
    >
      <SimpleCarousel
        title="Coming Soon"
        subtitle="Upcoming series to watch out for"
        :items="comingTVs"
        :component="'TVCard'"
        link="/tv/release-calendar"
      />
    </v-lazy>
    <v-lazy
      v-model="isTVSpotlightListActive"
      min-height="400px"
    >
      <SimpleCarousel
        title="Spotlights"
        subtitle="It's time for"
        :items="tvSpotlights"
        :component="'TVCard'"
        link="/spotlight"
        class="mb-8"
      />
    </v-lazy>
    <v-lazy
      v-model="isTVKeywordActive"
    >
      <KeywordCarousel
        title="Top TV Keywords"
        subtitle="Popular TV Series keywords to explore"
        :items="tvKeywords"
        base-link="/discover/tv-shows/keywords"
        class="mb-16"
      />
    </v-lazy>
    <v-lazy
      v-model="isGameListActive"
      min-height="300px"
    >
      <SimpleCarousel
        title="Trending Games"
        subtitle="Popular games to play"
        :items="bestGames"
        :component="'GameCard'"
        link="/game/most-popular"
        class="mb-8"
      />
    </v-lazy>
    <v-lazy
      v-model="isGameComingListActive"
      min-height="300px"
    >
      <SimpleCarousel
        title="Coming Soon"
        subtitle="Upcoming games to play"
        :items="comingGames"
        :component="'GameCard'"
        link="/game/release-calendar"
      />
    </v-lazy>
    <v-lazy
      v-model="isGameSpotlightListActive"
      min-height="400px"
    >
      <SimpleCarousel
        title="Spotlights"
        subtitle="It's time for"
        :items="gameSpotlights"
        :component="'GameCard'"
        link="/spotlight"
        class="mb-8"
      />
    </v-lazy>
    <v-lazy
      v-model="isGameKeywordActive"
    >
      <KeywordCarousel
        title="Top Game Keywords"
        subtitle="Popular Game keywords to explore"
        :items="gameKeywords"
        base-link="/discover/games/keywords"
        class="mb-16"
      />
    </v-lazy>
    <v-lazy
      v-model="isBookListActive"
      min-height="300px"
    >
      <SimpleCarousel
        title="Reading Books"
        subtitle="What people are reading"
        :items="readingBooks"
        :component="'BookCard'"
        link="/book/most-reading"
        class="mb-8"
      />
    </v-lazy>
    <v-lazy
      v-model="isBookMostWantedListActive"
      min-height="300px"
    >
      <SimpleCarousel
        title="Growing Popularity"
        subtitle="Most popular books to read"
        :items="bestBooks"
        :component="'BookCard'"
        link="/book/most-wanted"
        class="mb-8"
      />
    </v-lazy>
    <v-lazy
      v-model="isBookSpotlightListActive"
      min-height="400px"
    >
      <SimpleCarousel
        title="Spotlights"
        subtitle="It's time for"
        :items="bookSpotlights"
        :component="'BookCard'"
        link="/spotlight"
        class="mb-8"
      />
    </v-lazy>
    <v-lazy
      v-model="isBookSubjectActive"
    >
      <SubjectCarousel
        title="Top Book Subjects"
        subtitle="Popular Book subjects to explore"
        :items="bookSubjects"
        class="mb-8"
      />
    </v-lazy>
    <v-lazy
      v-model="isNewsListActive"
      min-height="300px"
    >
      <NewsCarousel
        title="News"
        subtitle="Latest news from the world of entertainment"
        :items="news"
        link="/news"
      />
    </v-lazy>
  </v-container>
</template>

<script>
import Movie from "@/services/movie";
import TV from "@/services/tv";
import Book from "@/services/book";
import Game from "@/services/game";
import News from "@/services/news";
import text from "@/mixins/text";
import image from "@/mixins/image";
import Spotlight from "@/services/spotlight";

export default {
  components: {
    SimpleCarousel: () => import("@/components/carousels/SimpleCarousel"),
    KeywordCarousel: () => import("@/components/carousels/KeywordCarousel"),
    SubjectCarousel: () => import("@/components/carousels/SubjectCarousel"),
    NewsCarousel: () => import("@/components/carousels/NewsCarousel"),
    BackgroundColor: () => import("@/components/BackgroundColor.vue"),
  },
  mixins: [text, image],
  data () {
    return {
      isMovieListActive: false,
      isMovieComingListActive: false,
      isMovieSpotlightListActive: false,
      isMovieKeywordActive: false,
      //
      isTVListActive: false,
      isTVComingListActive: false,
      isTVSpotlightListActive: false,
      isTVKeywordActive: false,
      //
      isGameListActive: false,
      isGameComingListActive: false,
      isGameSpotlightListActive: false,
      isGameKeywordActive: false,
      //
      isBookListActive: false,
      isBookMostWantedListActive: false,
      isBookSpotlightListActive: false,
      isBookSubjectActive: false,
      //
      isNewsListActive: false,
      //
      hotFilms: [],
      comingFilms: [],
      movieSpotlights: [],
      movieKeywords: [],
      //
      comingTVs: [],
      hotShows: [],
      tvSpotlights: [],
      tvKeywords: [],
      //
      bestGames: [],
      comingGames: [],
      gameSpotlights: [],
      gameKeywords: [],
      //
      bestBooks: [],
      readingBooks: [],
      bookSpotlights: [],
      bookSubjects: [],
      //
      news: [],
    };
  },
  head () {
    const title = "Flick Club";
    const description = "Flick Club: Your go-to platform for tracking and exploring Movies, TV Series, Books, and Video Games. " +
      "Join our community and stay connected to the latest releases, personalized recommendations, and engaging discussions. " +
      "Elevate your entertainment experience with Flick Club!";
    const image = "https://flick.club/flick-logo-with-background.jpg";
    const url = this.$route.fullPath;

    return {
      title,
      titleTemplate: null,
      description,
      link: [
        { rel: "canonical", href: "https://flick.club" },
      ],
      meta: [
        { hid: "robots", name: "robots", content: "index, nofollow" },
        { hid: "googlebot", name: "googlebot", content: "index, nofollow" },
        // Standard
        { hid: "title", name: "title", content: title },
        { hid: "description", name: "description", content: description },
        { hid: "image", name: "image", content: image },
        // Open Graph
        { hid: "og-type", property: "og:type", content: "website" },
        { hid: "og-title", property: "og:title", content: title },
        { hid: "og-description", property: "og:description", content: description },
        { hid: "og-image", property: "og:image", content: image },
        { hid: "og-url", property: "og:url", content: url },
        // Twitter
        { hid: "twitter-card", name: "twitter:card", content: "summary_large_image" },
        { hid: "twitter-title", name: "twitter:title", content: title },
        { hid: "twitter-description", name: "twitter:description", content: description },
        { hid: "twitter-image", name: "twitter:image", content: image },
        { hid: "twitter-url", name: "twitter:url", content: url },
      ],
    };
  },
  watch: {
    isMovieListActive () {
      const movieAPI = new Movie(this.$axios);
      movieAPI.getPopular(0, 12).then((bestFilms) => {
        this.hotFilms = bestFilms;
      });
    },
    isMovieComingListActive () {
      const movieAPI = new Movie(this.$axios);
      movieAPI.getRelease(0, 12).then((comingFilms) => {
        this.comingFilms = comingFilms;
      });
    },
    isMovieSpotlightListActive () {
      const spotlighAPI = new Spotlight(this.$axios);
      spotlighAPI.getMovieSpotlights().then((comingFilms) => {
        this.movieSpotlights = comingFilms;
      });
    },
    isMovieKeywordActive () {
      const movieAPI = new Movie(this.$axios);
      movieAPI.getTopKeywords(0, 24).then((genres) => {
        this.movieKeywords = genres;
      });
    },
    isTVListActive () {
      const tvAPI = new TV(this.$axios);
      tvAPI.getPopular(0, 12)
        .then((bestShows) => {
          this.hotShows = bestShows;
        });
    },
    isTVComingListActive () {
      const tvAPI = new TV(this.$axios);
      tvAPI.getRelease(0, 12)
        .then((comingTVs) => {
          this.comingTVs = comingTVs;
        });
    },
    isTVSpotlightListActive () {
      const spotlighAPI = new Spotlight(this.$axios);
      spotlighAPI.getTVSpotlights().then((comingFilms) => {
        this.tvSpotlights = comingFilms;
      });
    },
    isTVKeywordActive () {
      const tvAPI = new TV(this.$axios);
      tvAPI.getTopKeywords(0, 24).then((genres) => {
        this.tvKeywords = genres;
      });
    },
    isGameListActive () {
      const gameAPI = new Game(this.$axios);
      gameAPI.getPopular(0, 12)
        .then((bestGames) => {
          this.bestGames = bestGames;
        });
    },
    isGameComingListActive () {
      const gameAPI = new Game(this.$axios);
      gameAPI.getRelease(0, 12)
        .then((comingGames) => {
          this.comingGames = comingGames;
        });
    },
    isGameSpotlightListActive () {
      const spotlighAPI = new Spotlight(this.$axios);
      spotlighAPI.getGameSpotlights().then((comingFilms) => {
        this.gameSpotlights = comingFilms;
      });
    },
    isGameKeywordActive () {
      const gameAPI = new Game(this.$axios);
      gameAPI.getTopKeywords(0, 24).then((genres) => {
        this.gameKeywords = genres;
      });
    },
    isBookListActive () {
      const bookAPI = new Book(this.$axios);
      bookAPI.getReading(0, 12)
        .then((readingBooks) => {
          this.readingBooks = readingBooks;
        });
    },
    isBookMostWantedListActive () {
      const bookAPI = new Book(this.$axios);
      bookAPI.getWantToRead(0, 12)
        .then((bestBooks) => {
          this.bestBooks = bestBooks;
        });
    },
    isBookSpotlightListActive () {
      const spotlighAPI = new Spotlight(this.$axios);
      spotlighAPI.getBookSpotlights().then((comingFilms) => {
        this.bookSpotlights = comingFilms;
      });
    },
    isBookSubjectActive () {
      const bookAPI = new Book(this.$axios);
      bookAPI.getTopSubjects(0, 24)
        .then((subjects) => {
          this.bookSubjects = subjects;
        });
    },
    isNewsListActive () {
      const newsAPI = new News(this.$axios);
      newsAPI.getGeneralNews()
        .then((news) => {
          this.news = news;
        });
    },
  },
};
</script>
