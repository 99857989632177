import Base from "./base";

export default class Book extends Base {
  async getDetailInfo (slug) {
    return await this.makeGetRequest(`/books/${slug}`);
  }

  async getTopRated (offset, limit, params) {
    return await this.makeGetRequest("/books/top-rated", { offset, limit, ...params });
  }

  async getWantToRead (offset, limit, params) {
    return await this.makeGetRequest("/books/want-to-read", { offset, limit, ...params });
  }

  async getReading (offset, limit, params) {
    return await this.makeGetRequest("/books/reading", { offset, limit, ...params });
  }

  async getTopSubjects(offset, limit, params) {
    return await this.makeGetRequest("/books/top-subjects", { offset, limit, ...params });
  }
}
