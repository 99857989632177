import Base from "./base";

export default class Game extends Base {
  async getOverYears (offset, limit, params) {
    return await this.makeGetRequest("/games/over-years", { offset, limit, ...params });
  }

  async getDetailInfo (slug) {
    return await this.makeGetRequest(`/games/${slug}`);
  }

  async getTopRated (offset, limit, params) {
    return await this.makeGetRequest("/games/top-rated", { offset, limit, ...params });
  }

  async getPopular (offset, limit, params) {
    return await this.makeGetRequest("/games/popular", { offset, limit, ...params });
  }

  async getRelease (offset, limit, params) {
    return await this.makeGetRequest("/games/release-calendar", { offset, limit, ...params });
  }

  async getTopKeywords (offset, limit, params) {
    return await this.makeGetRequest("/games/top-keywords", { offset, limit, ...params });
  }
}
