import { marked } from "marked";
import xss from "xss";

export default {
  methods: {
    truncate (str, n) {
      if (str && str.length > n) {
        return (str.length > n) ? str.substr(0, n - 3) + "..." : str;
      } else {
        return "";
      }
    },
    markdownToHtml (value) {
      return value ? xss(marked(value)) : "";
    },
    getUserName () {
      if (!this.$store.state.user) {
        return `@${this.$route.params.username}`;
      }

      if (this.$route.params.username === this.$store.state.user.username) {
        return "Your";
      }
    },
  },
};
