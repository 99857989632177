import Base from "./base";

export default class Movie extends Base {
  async getOverYears (offset, limit, params) {
    return await this.makeGetRequest("/movies/over-years", { offset, limit, ...params });
  }

  async getDetailInfo (slug) {
    return await this.makeGetRequest(`/movies/${slug}`);
  }

  async getTopRated (offset, limit, params) {
    return await this.makeGetRequest("/movies/top-rated", { offset, limit, ...params });
  }

  async getRelease (offset, limit, params) {
    return await this.makeGetRequest("/movies/release-calendar", { offset, limit, ...params });
  }

  async getPopular (offset, limit, params) {
    return await this.makeGetRequest("/movies/popular", { offset, limit, ...params });
  }

  async getTopKeywords (offset, limit, params) {
    return await this.makeGetRequest("/movies/top-keywords", { offset, limit, ...params });
  }

  async getCast (slug, offset, limit, params) {
    return await this.makeGetRequest(`/movies/${slug}/cast`, { offset, limit, ...params });
  }

  async getCrew (slug, offset, limit, params) {
    return await this.makeGetRequest(`/movies/${slug}/crew`, { offset, limit, ...params });
  }
}
